import { API_URL, ZONEVACATAIRE } from "../ApiPaths";
interface AddVacataireParams {
  eventId: string;
  serviceName: string;
  zoneId: string;
  vacataireData: {
    vacataire: string;
    startHour?: string;
    jobs?: string[];
    subjobs?: string[];
  };
}
export interface ConvoqType {
  eventId: string;
  eventName?: string;
  serviceName: string;
  placeName?: string;
  placeDescription?: string;
  zoneSecondaryName?: string;
  vacataireId: string;
  zoneName: string;
  firstname: string;
  type: string;
  homeTeam?: string;
  awayTeam?: string;
  eventDate?: number;
  convocTime: any;
  category: string;
  fonction: string;
  dressCode: string;
  dressCodeDescription?: string;
}

export const updateVacataireStatus = async ({
  zoneId,
  vacataireId,
  status,
}: {
  zoneId: string;
  vacataireId: string;
  status: string;
}) => {
  const response = await fetch(
    `/api/zones/${zoneId}/vacataires/${vacataireId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to update vacataire status.");
  }

  return response.json();
};

// Dans votre fichier api
export const deleteVacataireFromZone = async ({
  vacataireId,
  eventId,
  serviceName,
  zoneName,
}: {
  vacataireId: string;
  eventId: string;
  serviceName: string;
  zoneName: string;
}) => {
  const response = await fetch(
    `${API_URL}${ZONEVACATAIRE}/${eventId}/${serviceName}/${zoneName}/vacataire/${vacataireId}`,
    {
      method: "DELETE",
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Failed to delete vacataire.");
  }

  return response.json();
};

/** Good */
export const fetchZonesForEventAndService = async (
  eventId: string,
  serviceName: string
): Promise<any> => {
  const response = await fetch(
    `${API_URL}${ZONEVACATAIRE}/${eventId}/${serviceName}`,
    {
      method: "GET",
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch zones for event and service");
  }

  return response.json();
};

export const createZoneInEvent = async (data: {
  name: string;
  eventId: string;
  service: string;
}): Promise<any> => {
  const response = await fetch(`${API_URL}${ZONEVACATAIRE}`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error("Failed to add zone to event and service");
  }

  return response.json();
};

export const addVacataireToZone = async ({
  eventId,
  serviceName,
  zoneId,
  vacataireId,
  category,
  dressCode,
}: any): Promise<any> => {
  const url = `${API_URL}${ZONEVACATAIRE}/${eventId}/${serviceName}/${zoneId}/vacataires`;
  const response = await fetch(url, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      vacataireId,
      category,
      dressCode,
    }),
  });

  // Vérifie si la réponse est un succès
  if (!response.ok) {
    const errorData = await response.json(); // Récupère les détails de l'erreur
    const error = new Error(
      errorData.message || "Erreur lors de l'ajout du vacataire"
    );
    // Ajoute le statut HTTP pour le traitement dans `onError`
    (error as any).status = response.status;
    throw error;
  }

  return response.json();
};

export const updateVacataireDetailsInZone = async ({
  eventId,
  serviceName,
  zoneName,
  vacataireId,
  updateVacataireDto,
}: any): Promise<any> => {
  const url = `${API_URL}${ZONEVACATAIRE}/${eventId}/${serviceName}/${zoneName}/vacataires/${vacataireId}`;

  const response = await fetch(url, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updateVacataireDto),
  });

  if (!response.ok) {
    const responseData = await response.json();
    throw new Error(
      responseData.message || "Erreur lors de la mise à jour du vacataire."
    );
  }

  return response.json();
};

export const deleteZoneForEvent = async ({
  eventId,
  serviceName,
  zoneName,
}: {
  eventId: string;
  zoneName: string;
  serviceName: string;
}) => {
  const response = await fetch(
    `${API_URL}${ZONEVACATAIRE}/${eventId}/${serviceName}/${zoneName}`,
    {
      method: "DELETE",
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Failed to delete vacataire.");
  }

  return response.json();
};

export const sendConvocationEmail = async (convocationData: ConvoqType) => {
  try {
    const response = await fetch(
      `${API_URL}${ZONEVACATAIRE}/send-convocation`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(convocationData),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message || "Une erreur inconnue s'est produite"
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // Gérez ici les erreurs de connexion ou autres
    throw error; // Nous propageons l'erreur telle quelle
  }
};

export const sendTestConvocMail = async (convocationData: ConvoqType) => {
  try {
    const response = await fetch(
      `${API_URL}${ZONEVACATAIRE}/send-fake-convocation`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(convocationData),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message || "Une erreur inconnue s'est produite"
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // Gérez ici les erreurs de connexion ou autres
    throw error; // Nous propageons l'erreur telle quelle
  }
};

export const sendRelanceEmail = async (convocationData: ConvoqType) => {
  try {
    const response = await fetch(
      `${API_URL}${ZONEVACATAIRE}/relance-convocation`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(convocationData),
      }
    );

    if (!response.ok) {
      // Gérez ici les erreurs en fonction de la réponse du serveur
      throw new Error("Une erreur s'est produite lors de la convocation");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // Gérez ici les erreurs de connexion ou autres
    throw new Error("Une erreur s'est produite lors de la convocation");
  }
};
